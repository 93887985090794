import { AccessToken } from '@dactyl-npm-packages/dactyl-auth-react'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { FieldPath } from 'react-hook-form/dist/types/path'
import { FormProvider, useForm } from 'react-hook-form'
import { LoginFormAdminCustomerSelector } from './LoginFormAdminCustomerSelector'
import { LoginRequestData, useSignIn } from '../../auth/auth'
import { handleFormErrorsFromAxios } from '../../utils/ApiHelper'
import { object, string } from 'yup'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import Alert from '../base/alert/Alert'
import Button from '../base/button/Button'
import Column from '../base/grid/Column'
import Form from '../base/form/Form'
import React, { useState } from 'react'
import Row from '../base/grid/Row'
import TextField from '../base/text-field/TextField'
import useTheme from '../../hooks/useTheme'

export type LoginReponse = {
    id: number
    firstName: string
    lastName: string
    email: string
    type: string
    token: AccessToken
}

export type LoginFormProps = {
    admin?: boolean
}

const LoginForm = ({ admin }: LoginFormProps) => {
    const { t } = useTranslation()
    const router = useRouter()
    const { signIn, isLoading } = useSignIn<LoginRequestData>()
    const {
        theme: { oldSystemUrl }
    } = useTheme()
    const [showAdminSelector, setShowAdminSelector] = useState(false)

    const form = useForm<LoginRequestData>({
        resolver: yupResolver(
            object({
                username: string().required(t('frontend.user.login.required')),
                password: string().required(t('frontend.user.password.required'))
            })
        ),
        reValidateMode: 'onChange'
    })

    const {
        handleSubmit,
        register,
        setError,
        formState: { errors }
    } = form

    const submit = (data: LoginRequestData) => {
        signIn(data)
            .then(response => {
                const apiResponse = response as unknown as LoginReponse
                const userType = apiResponse.type
                if (admin && userType !== 'D') {
                    return setShowAdminSelector(true)
                }
                router.push('/app')
            })
            .catch(error => {
                handleFormErrorsFromAxios(error, setError)
            })
    }

    const getFieldError = (path: FieldPath<LoginRequestData>) => {
        return errors[path]?.message
    }

    const renderChooseCustomerSelector = () => {
        return (
            <Row>
                {admin && (
                    <Column cols={12}>
                        <LoginFormAdminCustomerSelector />
                    </Column>
                )}
            </Row>
        )
    }

    if (showAdminSelector) {
        return renderChooseCustomerSelector()
    }

    return (
        <FormProvider {...form}>
            <Form className='space-y-6' onSubmit={handleSubmit(submit)}>
                <Row>
                    <Column cols={12}>
                        <Alert color='info' className='text-body-small'>
                            <div className='mt-1'>
                                Pro přihlášení použijte své aktuální přihlašovací údaje ze současného systému.
                            </div>
                        </Alert>
                    </Column>
                    <Column cols={12}>
                        <TextField
                            label={t('frontend.user.login')}
                            type='text'
                            inputClassName='!py-3.5 !bg-grey-100 !border-none'
                            message={getFieldError('username')}
                            valid={!getFieldError('username')}
                            {...register('username')}
                        />
                    </Column>
                    <Column cols={12}>
                        <TextField
                            label={t('frontend.user.password')}
                            inputClassName='!py-3.5 !bg-grey-100 !border-none'
                            type='password'
                            message={getFieldError('password')}
                            valid={!getFieldError('password')}
                            {...register('password')}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column cols={12}>
                        <Button type='submit' className='w-full !py-2.5 !text-base !font-medium' loading={isLoading}>
                            {t('frontend.user.login_button')}
                        </Button>
                    </Column>
                    <Column cols={12}>
                        <Button
                            as='a'
                            href={oldSystemUrl}
                            target='_blank'
                            styleType='text'
                            className='w-full !py-2.5 !text-base !font-medium'
                            loading={isLoading}
                            rightIcon={<ChevronRightIcon />}
                        >
                            {t('frontend.user.go_to_old_system')}
                        </Button>
                    </Column>
                </Row>
            </Form>
        </FormProvider>
    )
}

export default LoginForm
